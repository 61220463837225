@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@900&display=swap');


@font-face {
  font-family: 'Dortmund-Extrabold';
  src: url('/public/toxigenesis\ bd.otf') format('opentype');
}



html, body {
  font-family: 'Bebas Neue', sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  /* Für Webkit-Browser wie Safari */
  -webkit-overflow-scrolling: touch;
}




.underline-link {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;

}

.underline-link:hover, 
.underline-link:focus {
  color: inherit;
  text-decoration: underline;
}




#loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #edacf0;
  z-index: 1000;
  transition: width 0.3s ease;
}

/* Background.css */


.background-image, .background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; /* Das Bild wird so skaliert, dass es den Container ausfüllt, wobei der obere Teil des Bildes sichtbar bleibt. */
  filter: url('#turbulent');
  width: 110%; /* Diese Übergröße hilft dabei, das Flimmern oder Flackern am Rand zu reduzieren. */
  height: 110%;
  top: -5%;
  left: -5%;
}

.background-overlay {
  z-index: 0;
  background: rgba(255, 47, 168, 0.193); /* rosa, halbtransparent */
  transition: background 0.9s; /* Animationseffekt beim Scrollen */
  
}

.background-overlay.scrolling {
  background: #ff080c; /* rot, weniger transparent beim Scrollen */

}






.fixed-title {
  pointer-events: none;
  font-family: 'Dortmund-Extrabold';
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: calc(90vw);
  text-align: center;
  top: -10px; /* Setze hier den gewünschten Abstand nach oben ein. */

  z-index: 0;
  transition: color 0.5s, opacity 0.3s;
  color: rgb(254, 247, 239);
}

.fixed-title.scrolled {
  color: rgb(255, 0, 0);
  opacity: 0.8;
  z-index: 1;
  font-style: italic;
}



.akkordion-wrapper {
 
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}

.accordion {
   
    min-width: 20vw;
    max-width: 90vw;
    color: antiquewhite;
    border: none;
    margin-top: 30px;
    margin-bottom: 20vh;
    text-align: justify;
    margin-bottom: 1500px;
    
}

.accordion-item {
    border-top: 1px solid #eee;
    position: relative;
}

.accordion-header {
   
    cursor: pointer;
    position: relative;
    text-align: left;
    line-height: 1.5;
    transition: color 0.3s;
    -webkit-tap-highlight-color: rgba(255,182,193,0.2); 
}
.accordion-header:hover {
   color: rgb(252, 228, 180);
   font-style: italic;
   

}

.accordion-header.touched {
  color: rgb(252, 228, 180);
  font-family: 'Ecto Blaster';
}

.accordion-close {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: antiquewhite;
}

.accordion-body {
    padding: 10px 0;
    background: transparent;
}

.image-content {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  border-radius: 10px;
  z-index: 0;
  opacity: 0.9;
  margin-bottom: 2vh;
}

.accordion-table {
  width: 100%;
  border-spacing: 0px 8px;
  color: antiquewhite;
  padding-bottom: 4vh;
  text-align: left;
  
}

.accordion-table td {
  background-color: transparent;
  vertical-align: top;
  padding-top: 15px;
  padding-bottom: 20px;
}

.accordion-table td:first-child {
  text-align: left; 
  width: 70px; /* Hier können Sie die gewünschte Breite definieren */
}



.cv-überschrift {
  color: rgb(252, 228, 180);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.selected-work-header {
  margin-top: 80px;
  
}

/*responsive*/
/* Für sehr kleine Geräte */





@media only screen and (max-width: 480px) {
  .accordion {
    width: calc(100vw - 15vw);
  }
  .akkordion-wrapper {
    padding-top: 250px;
  }
  .accordion-text {
    font-size: 1.1rem;
  }
  .accordion-header {
    font-size:1.3rem;
    padding: 5px 0;
  }
  .selected-work-header {
    font-size: 1.3rem;
    
  }
  .cv-title {
    font-size: 1.3rem;
  }
  .accordion-table td { 
    font-size: 1rem; 
  }
  .fixed-title {
    font-size: 40px;
  }
}


@media only screen and (min-width: 481px) and (max-width: 768px) {
  .accordion {
    width: calc(100vw - 25vw);
  }
  .akkordion-wrapper {
    padding-top: 240px;
  }
  .accordion-text {
    font-size: 1.1rem;
  }
  .accordion-header {
    font-size:1.4rem;
    padding: 8px 0;
  }

  .selected-work-header {
    font-size:  1.4rem;   
  }

  .cv-title {
    font-size: 1.4rem;
  }
  .accordion-table td { 
    font-size: 1.1rem; 
  }
  .fixed-title {
    font-size: 53px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .accordion {
    width: calc(100vw - 25vw);
  }
  .akkordion-wrapper {
    padding-top: 240px;
  }
  .accordion-text {
    font-size: 1.3rem;
  }
  .accordion-header {
    font-size:1.6rem;
    padding: 9px 0;
  }
  .selected-work-header {
    font-size: 1.6rem;
  }
  .cv-title {
    font-size: 1.5rem;
  }
  .accordion-table td { 
    font-size: 1.2rem; 
  }
  .fixed-title {
    font-size: 80px;
  }

}


@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .accordion {
    width: calc(100vw - 30vw);
  }
  .akkordion-wrapper {
    padding-top: 300px;
  }
  .accordion-text {
    font-size: 1.3rem;
  }
  .accordion-header {
    font-size: 1.6rem;
    padding: 9px 0;
  }
  .selected-work-header {
    font-size: 1.6rem;
  }
  .cv-title {
    font-size: 1.6rem;
  }
  .accordion-table td { 
    font-size: 1.3rem; 
  }
  .fixed-title {
    font-size: 87px;
  }

  
}

@media only screen and (min-width: 1201px) {
  .accordion {
    width: calc(100vw - 40vw);
  }
  .akkordion-wrapper {
    padding-top: 300px;
  }
  .accordion-text {
    font-size: 1.3rem;
  }
  .accordion-header {
    font-size: 1.7rem;
    padding: 9px 0;
  }
  .selected-work-header {
    font-size: 1.7rem;
  }
  .cv-title {
    font-size: 1.5rem;
  }
  .accordion-table td { 
    font-size: 1.3rem; 
  }
  .fixed-title {
    font-size: 90px;
  }
  

}

@media only screen and (min-width: 1401px) {
  .accordion {
    width: calc(100vw - 58vw);
  }
}

@media only screen and (min-width: 2560px) and (max-width: 3440px) {
  .accordion {
    width: calc(100vw - 75vw);
  }
  .akkordion-wrapper {
    padding-top: 400px;
  }
  .accordion-text {
    font-size: 1.3rem;
  }
  .accordion-header {
    font-size:1.7rem;
    padding: 9px 0;
  }
  .selected-work-header {
    font-size: 1.7rem;
  }
  .cv-title {
    font-size: 1.6rem;
  }
  .accordion-table td { 
    font-size: 1.3rem; 
  }
  .fixed-title {
    font-size: 95px;
  }
  .background-image {
    background-size: contain;
    background-position: center;
    width: 100%;
  
    top: 0;
    left: 0; 
  }
}